import LoaderBig from '@/shared/components/loader-big/loader-big';
import { useGetAppSportsQuery, useGetBannersQuery, useGetCurrenciesQuery, useGetStatusQuery } from '@shared/store/app/app-api';
import { useCheckAuthQuery, useGetUserGeoQuery, useGetUserWalletsQuery } from '@/modules/user';
import { PropsWithChildren, useEffect, useState } from 'react';
import { SiteMaintenance } from '@/widgets/site-maintenance';
import { useGetUnreadNotificationsCountQuery } from '@/modules/notifications';
import { useGetCasinoBonusesQuery, useGetFavoriteMatchesQuery, useGetFavoritesGamesQuery } from '@/modules/user/store/user-api';
import { useFetchCasinoGames } from '@/modules/casino/model/use-fetch-games';
import { useGetLastwinsQuery } from '@/modules/casino/api/casino-api';
import { useAppSelector } from '@/shared/hooks';
import { getIsAuth } from '@/modules/auth';

const AppLoader = ({ children }: PropsWithChildren) => {
  const isAuth = useAppSelector(getIsAuth);
  const { isLoading: isStatusLoading, data: status } = useGetStatusQuery();
  const { isLoading: isAuthLoading, isSuccess: isAuthSuccess } =
    useCheckAuthQuery(undefined, { skip: !status?.system });
  const { isLoading: isUserGeoLoading } = useGetUserGeoQuery();
  useGetBannersQuery(undefined, { skip: !status?.system });
  useGetCurrenciesQuery(undefined, { skip: !status?.system });
  useGetAppSportsQuery();
  const { isLoading: isLaswWinsLoading } = useGetLastwinsQuery(undefined, { skip: !status?.system });

  const { isLoading: isCasinoLoading } = useFetchCasinoGames();

  useGetCasinoBonusesQuery(undefined, {
    skip: !status?.system || !isAuthSuccess || !isAuth,
    pollingInterval: 10000
  });
  const { isLoading: isWalletLoading } = useGetUserWalletsQuery(undefined, {
    skip: !status?.system || !isAuthSuccess || !isAuth,
    pollingInterval: 10000
  });
  useGetUnreadNotificationsCountQuery(undefined, {
    pollingInterval: 60000,
    skip: !status?.system || !isAuthSuccess || !isAuth,
  });
  useGetFavoriteMatchesQuery(undefined, { skip: !status?.system || !isAuthSuccess || !isAuth, });
  useGetFavoritesGamesQuery(undefined, { skip: !status?.system || !isAuthSuccess, });

  const [isAppLoaded, setAppLoaded] = useState(false);

  const isLoading = isStatusLoading
    || isAuthLoading
    || isUserGeoLoading
    || isWalletLoading
    || isCasinoLoading
    || isLaswWinsLoading;

  useEffect(() => {
    if (!isLoading) {
      setAppLoaded(true);
    }
  }, [isLoading]);

  if (!isAppLoaded) {
    return <LoaderBig />;
  }

  if (!status?.system) {
    return <SiteMaintenance />;
  }


  return children;
};


export default AppLoader;
